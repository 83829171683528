export function generateShareLink(pageUrl: string, type: string, text: string, option?: string) {
  let link = '#';
  if (type === 'whatsapp') {
    link = `https://api.whatsapp.com/send?text=${text} - ${pageUrl}`;
  } else if (type === 'facebook') {
    link = `https://www.facebook.com/sharer.php?t=${text}&u=${pageUrl}`;
  } else if (type === 'telegram') {
    link = `https://t.me/share/url?url=${pageUrl}&text=${text}`;
  } else if (type === 'twitter') {
    link = `https://twitter.com/intent/tweet?text=${text}&url=${pageUrl}`;
  } else if (type === 'messenger') {
    link = `fb-messenger://share/?link=${pageUrl}&app_id=${option}`;
  }
  return link;
}
