/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { motion } from 'framer-motion';
import { BASE_URL, COOKIE_NAME, GTM_ID } from 'libs/constants';

import 'src/styles/next.scss';
import { withRouter } from 'next/router';
import { GlobalFooter } from 'components/organisms/global-footer';
import { Whole } from 'components/organisms/whole';
import { GlobalHeader } from 'components/organisms/global-header';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import TagManager from 'react-gtm-module';
import Link from 'next/link';

/* const headerVariants = {
  hidden: { opacity: 0, y: -88 },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      // duration: 1,
      // type: 'spring',
      // stiffness: 400,
      // damping: 40,
      duration: 1,
      // ease: [.02,.58,.53,.89]
    },
  },
}; */

const pagePathList = [
  '/',
  '/about',
  '/service-list',
  '/products-list',
  '/products-list/rtgs',
  '/products-list/rtp',
  '/products-list/csd',
  '/products-list/ach-bp',
  '/products-list/cts',
  '/products-list/gateway',
  '/products-list/dashboard',
  '/products-list/non-functional',
  '/case-study',
  '/contact-us',
  '/terms-and-conditions',
  '/privacy-policy',
];

function BSAApp({ Component, pageProps, router }: AppProps) {
  const SITE_BASE_PATH = process.env.SITE_BASE_PATH || '';

  const [isLoading, setIsLoading] = useState(false);
  const [acceptCookie, setAcceptCookie] = useState(Cookies.get(COOKIE_NAME));

  useEffect(() => {
    setAcceptCookie(Cookies.get(COOKIE_NAME));
  }, []);
  useEffect(() => {
    if (acceptCookie)
      TagManager.initialize({
        gtmId: GTM_ID,
      });
  }, [acceptCookie]);

  useEffect(() => {
    pagePathList
      .filter(x => router.route !== x)
      .forEach(pageUrl => {
        router.prefetch(pageUrl);
      });
  }, [router]);

  useEffect(() => {
    const handleRouteChangeStart = (url: string, options: any) => {
      if (url !== router.asPath && !options.shallow) {
        setIsLoading(true);
      }
    };
    const handleRouteChangeComplete = (url: string, options: any) => {
      if (url === router.asPath && !options.shallow) {
        setIsLoading(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.asPath, router.events]);

  return (
    <div className="app-main">
      <DefaultSeo
        titleTemplate="%s | v-PaSSer"
        defaultTitle="v-PaSSer - Payment and Settlement Services"
        description="With our abundant experiences in financial IT infrastructure, v-PaSSer assists you in achieving your goals with customization-based system and extraordinary support."
        additionalMetaTags={[
          {
            name: 'keywords',
            content: 'v-PaSSer - Payment and Settlement Services',
          },
          // { name: 'author', content: 'Super User' },
          {
            name: 'description',
            content:
              'With our abundant experiences in financial IT infrastructure, v-PaSSer assists you in achieving your goals with customization-based system and extraordinary support.',
          },
          { name: 'generator', content: 'Designed & Developed by FOURDIGIT INC.' },
          { name: 'msapplication-TileColor', content: '#ffffff' },
          { name: 'theme-color', content: '#ffffff' },
        ]}
        additionalLinkTags={[
          { rel: 'apple-touch-icon', href: `${SITE_BASE_PATH}/apple-touch-icon.png`, sizes: '180x180' },
          { rel: 'icon', type: 'image/x-icon', href: `${SITE_BASE_PATH}/favicon.ico` },
          { rel: 'shortcut icon', type: 'image/x-icon', href: `${SITE_BASE_PATH}/favicon.ico` },
          { rel: 'icon', type: 'image/png', href: `${SITE_BASE_PATH}/favicon-16x16.png`, sizes: '16x16' },
          { rel: 'icon', type: 'image/png', href: `${SITE_BASE_PATH}/favicon-32x32.png`, sizes: '32x32' },
          { rel: 'manifest', href: `${SITE_BASE_PATH}/manifest.json` },
          { rel: 'mark-icon', href: `${SITE_BASE_PATH}/safari-pinned-tab.svg`, color: '#1d2e77' },
        ]}
        openGraph={{
          type: 'website',
          // locale: lang,
          url: `${BASE_URL}/`,
          title: 'v-PaSSer - Payment and Settlement Services',
          description:
            'With our abundant experiences in financial IT infrastructure, v-PaSSer assists you in achieving your goals with customization-based system and extraordinary support.',
          images: [
            {
              url: `${BASE_URL}/images/og.jpg`,
              width: 1200,
              height: 630,
              alt: 'v-PaSSer - Payment and Settlement Services',
            },
          ],
          site_name: 'v-PaSSer - Payment and Settlement Services',
        }}
      />
      {/* {isLoading && (
        <motion.div
          className="p-app__loader"
          key="core-loader"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: isLoading ? 1 : 0,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            duration: 1,
          }}
        >
          <span>Loading...</span>
        </motion.div>
      )} */}
      <Whole>
        <motion.div
          className="app-main__header"
          // initial="hidden"
          // animate="enter"
          // variants={headerVariants}
        >
          <GlobalHeader />
        </motion.div>
        <motion.div
          className="app-main__content"
          initial={false}
          animate={{
            opacity: isLoading ? 0 : 1,
          }}
          transition={{
            duration: isLoading ? 0.4 : 1,
          }}
        >
          {/* <AnimatePresence exitBeforeEnter initial={false} onExitComplete={() => window.scrollTo(0, 0)}>
            <Component {...pageProps} key={router.asPath} />
          </AnimatePresence> */}
          <Component {...pageProps} />
        </motion.div>
        <motion.div
          className="app-main__footer"
          initial={false}
          animate={{
            opacity: isLoading ? 0 : 1,
          }}
          transition={{
            duration: isLoading ? 0.4 : 1,
          }}
        >
          <GlobalFooter />
        </motion.div>
      </Whole>
      {!acceptCookie && (
        <div className="o-cookie-banner">
          <CookieConsent
            containerClasses="o-cookie-banner__wrapper"
            contentClasses="o-cookie-banner__content"
            overlayClasses="o-cookie-banner__overlay"
            buttonWrapperClasses="o-cookie-banner__buttons"
            buttonClasses="o-cookie-banner__button"
            buttonText="ACCEPT"
            cookieName={COOKIE_NAME}
            disableStyles={true}
            onAccept={() => {
              setAcceptCookie(true);
            }}
          >
            This website uses Cookies to provide a better experience. We use them to analyze how our sites are used,
            optimize advertising, and connect to social networking sites. We also share the information with third
            parties for advertising and analytics. You have the right to choose whether or not to accept Cookies. See
            our <Link href="/privacy-policy">Cookie Policy</Link> for details.
          </CookieConsent>
        </div>
      )}
    </div>
  );
}
export default withRouter(BSAApp);
