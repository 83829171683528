import React, { MouseEvent, useCallback, useState } from 'react';
import Link from 'next/link';
import { mapModifiers } from 'libs/component';
import { Col } from 'components/organisms/col';
import { Row } from 'components/organisms/row';
import { Container } from 'components/organisms/container';
import { Icon } from 'components/atoms/icon';
import { generateShareLink } from 'libs/generate-share-link';
import { BASE_URL, FB_APP_ID, SEO_INFO } from 'libs/constants';
import { ENV } from 'libs/env';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'libs/use-media-query';

export const GlobalFooter: React.FC = () => {
  const [expandLinks, setExpandLinks] = useState([false, false]);
  const isSP = useMediaQuery('(max-width: 768px)');

  const footerVariants = {
    root: {
      enter: isSP
        ? {
            maxHeight: 152,
            transition: {
              duration: 1,
              staggerChildren: 0.25,
            },
          }
        : {},
      exit: isSP
        ? {
            maxHeight: 0,
            transition: {
              duration: 1,
              staggerChildren: 0.05,
              staggerDirection: -1,
            },
          }
        : {},
    },
    item: {
      enter: isSP
        ? {
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
            },
          }
        : {},
      exit: isSP
        ? {
            opacity: 0,
            y: 150,
            transition: {
              duration: 0.5,
            },
          }
        : {},
    },
  };

  const handleShare = useCallback((e: MouseEvent, type: string) => {
    e.preventDefault();
    if (!ENV.IS_TOUCH_DEVICE && type === 'messenger') {
      if (window) {
        const pageUrl = BASE_URL;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).FB.ui({
          method: 'send',
          link: pageUrl,
        });
      }
    } else {
      let option = '';
      if (ENV.IS_TOUCH_DEVICE && type === 'messenger') {
        option = FB_APP_ID;
      }
      const link = generateShareLink(BASE_URL as string, type, SEO_INFO.HOME.title, option);
      if (link !== '#') {
        window.open(link, '_blank');
      }
    }
  }, []);

  const toggleLinkGroup = useCallback(
    index => {
      const currentStates = [...expandLinks];
      currentStates[index] = !currentStates[index];
      setExpandLinks(currentStates);
    },
    [expandLinks]
  );

  return (
    <div className={mapModifiers('o-global-footer')}>
      <div className="o-global-footer__content">
        <Container>
          <Row>
            <Col width={2} widthSP={4}>
              <div className="o-global-footer__logo">
                <img src="/images/logo.svg" alt="v-PaSSer" height="32" width="101" />
              </div>
            </Col>
            <Col width={4} widthSP={4}>
              <div className={mapModifiers('o-global-footer__links', 'link-group', expandLinks[0] && 'opened')}>
                <div className="o-global-footer__links-title" onClick={() => toggleLinkGroup(0)}>
                  <span>Quick Links</span>
                  {!expandLinks[0] && <Icon name="chevron-down" color="dark-navy"></Icon>}
                  {expandLinks[0] && <Icon name="chevron-up" color="dark-navy"></Icon>}
                </div>
                <motion.div
                  className="o-global-footer__links-items"
                  variants={footerVariants.root}
                  initial={false}
                  animate={expandLinks[0] ? 'enter' : 'exit'}
                >
                  <Row>
                    <Col width={1}>
                      <motion.div className="o-global-footer__links-item" variants={footerVariants.item}>
                        <Link href="/about" scroll={true}>
                          <a href="/about">
                            About
                            <Icon name="chevron-right" color="red" />
                          </a>
                        </Link>
                      </motion.div>
                      <motion.div className="o-global-footer__links-item" variants={footerVariants.item}>
                        <Link href="/service-list" scroll={true}>
                          <a href="/service-list">
                            Services
                            <Icon name="chevron-right" color="red" />
                          </a>
                        </Link>
                      </motion.div>
                    </Col>
                    <Col width={3}>
                      <motion.div className="o-global-footer__links-item" variants={footerVariants.item}>
                        <Link href="/products-list" scroll={true}>
                          <a href="/products-list">
                            Products
                            <Icon name="chevron-right" color="red" />
                          </a>
                        </Link>
                      </motion.div>
                      <motion.div className="o-global-footer__links-item" variants={footerVariants.item}>
                        <Link href="/case-study" scroll={true}>
                          <a href="/case-study">
                            Case Study - An ASEAN Country
                            <Icon name="chevron-right" color="red" />
                          </a>
                        </Link>
                      </motion.div>
                    </Col>
                  </Row>
                </motion.div>
              </div>
            </Col>
            <Col width={2} widthSP={4}>
              <div className={mapModifiers('o-global-footer__links', 'link-group', expandLinks[1] && 'opened')}>
                <div className="o-global-footer__links-title" onClick={() => toggleLinkGroup(1)}>
                  <span>Site Information</span>
                  {!expandLinks[1] && <Icon name="chevron-down" color="dark-navy"></Icon>}
                  {expandLinks[1] && <Icon name="chevron-up" color="dark-navy"></Icon>}
                </div>
                <motion.div
                  className="o-global-footer__links-items"
                  variants={footerVariants.root}
                  initial={false}
                  animate={expandLinks[1] ? 'enter' : 'exit'}
                >
                  <motion.div className="o-global-footer__links-item" variants={footerVariants.item}>
                    <Link href="/terms-and-conditions" scroll={true}>
                      <a href="/terms-and-conditions">
                        Terms & Conditions
                        <Icon name="chevron-right" color="red" />
                      </a>
                    </Link>
                  </motion.div>
                  <motion.div className="o-global-footer__links-item" variants={footerVariants.item}>
                    <Link href="/privacy-policy" scroll={true}>
                      <a href="/privacy-policy">
                        Privacy Policy
                        <Icon name="chevron-right" color="red" />
                      </a>
                    </Link>
                  </motion.div>
                </motion.div>
              </div>
            </Col>
            <Col width={2} widthSP={4}>
              <div className={mapModifiers('o-global-footer__links', 'contact')}>
                <div className="o-global-footer__links-title">
                  <Link href="/contact-us" scroll={true}>
                    <a href="/contact-us">
                      Contact Us
                      <Icon name="arrow-right-large" color="dark-navy" />
                    </a>
                  </Link>
                </div>
              </div>
            </Col>
            <Col width={2} widthSP={4}>
              <div className={mapModifiers('o-global-footer__links', 'social')}>
                <div className="o-global-footer__links-title">Share us on</div>
                <div className="o-global-footer__links-items">
                  <ul className="o-global-footer__socials">
                    <li>
                      <a
                        href="#"
                        target="_blank"
                        onClick={e => handleShare(e, 'whatsapp')}
                        title="Share us on WhatsApp"
                      >
                        <span>WhatsApp</span>
                        <Icon name="whatsapp" color="dark-navy" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        target="_blank"
                        onClick={e => handleShare(e, 'messenger')}
                        title="Share us on Messenger"
                      >
                        <span>Messenger</span>
                        <Icon name="messenger" color="dark-navy" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        target="_blank"
                        onClick={e => handleShare(e, 'telegram')}
                        title="Share us on Telegram"
                      >
                        <span>Telegram</span>
                        <Icon name="telegram" color="dark-navy" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        target="_blank"
                        onClick={e => handleShare(e, 'facebook')}
                        title="Share us on Facebook"
                      >
                        <span>Facebook</span>
                        <Icon name="facebook" color="dark-navy" />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank" onClick={e => handleShare(e, 'twitter')} title="Share us on Twitter">
                        <Icon name="twitter" color="dark-navy" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="o-global-footer__bottom">
        <Container>
          <Row>
            <Col width={9} widthSP={4}>
              <a href="https://www.nttdata.com/global/en" title="NTTDATA" target="_blank" rel="noreferrer">
                <img
                  className="o-global-footer__nttdata-logo"
                  src="/images/nttdata-logo.svg"
                  alt="NTTDATA"
                  height="14"
                  width="96"
                />
              </a>
            </Col>
            <Col width={3} widthSP={4}>
              <div className="o-global-footer__copy">Copyright &copy; NTT DATA Corporation</div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
