import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { mapModifiers } from 'libs/component';
import { Icon } from 'components/atoms/icon';
import { generateShareLink } from 'libs/generate-share-link';
import { BASE_URL, SEO_INFO } from 'libs/constants';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'libs/use-media-query';
import { ENV } from 'libs/env';
import { FB_APP_ID } from 'libs/constants';
import router from 'next/router';

const logoImage = '/images/logo.svg';

export interface GlobalHeaderProps {
  open?: boolean;
}

const navList = [
  { text: 'Home', path: '/' },
  { text: 'About', path: '/about' },
  { text: 'Services', path: '/service-list' },
  { text: 'Products', path: '/products-list' },
  { text: 'Case Study', path: '/case-study' },
  { text: 'Contact Us', path: '/contact-us' },
];

const defaultToggleButtonX = 327;

export const GlobalHeader: React.FC<GlobalHeaderProps> = ({ open = false }) => {
  const isSP = useMediaQuery('(max-width: 768px)');
  const [isOpen, setIsOpen] = useState(open);
  const backgroundRef = useRef(null);
  const toggleRef = useRef(null);
  const [toggleButtonX, setToggleButtonX] = useState(defaultToggleButtonX);
  const handleWindowResize = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toggleBtn: HTMLElement = toggleRef.current as any;
    const rect = toggleBtn?.getBoundingClientRect();
    if (rect) {
      setToggleButtonX(rect.right - 24);
    } else {
      setToggleButtonX(defaultToggleButtonX);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const backgroundEl: HTMLDivElement = backgroundRef.current as any;
    if (backgroundEl) {
      backgroundEl.style.clipPath = `circle(${isOpen ? 1000 : 0}px at ${toggleButtonX}px 48px)`;
    }
  }, [isOpen, toggleButtonX]);
  useEffect(() => {
    if (window) {
      window.addEventListener('resize', handleWindowResize);
    }
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const animationVariants = {
    root: {
      hidden: { opacity: 0, y: -88 },
      enter: {
        opacity: 1,
        y: 0,
        transition: {
          // duration: 1,
          // type: 'spring',
          // stiffness: 400,
          // damping: 40,
          duration: 1,
          ease: [0.02, 0.58, 0.53, 0.89],
        },
      },
    },
    background: isSP
      ? {
          open: {
            clipPath: `circle(1000px at ${toggleButtonX}px 48px)`,
            transition: {
              ease: 'easeInOut',
              duration: 0.6,
            },
          },
          closed: {
            clipPath: `circle(0px at ${toggleButtonX}px 48px)`,
            transition: {
              ease: 'easeInOut',
              duration: 0.5,
            },
          },
        }
      : {
          open: {},
          closed: {},
        },
    nav: isSP
      ? {
          open: {
            maxHeight: '1000px',
            transition: {
              staggerChildren: 0.07,
              delayChildren: 0.2,
            },
          },
          closed: {
            maxHeight: 0,
            transition: { staggerChildren: 0.05, staggerDirection: -1 },
          },
        }
      : {
          open: { maxHeight: 'none' },
          closed: { maxHeight: 'none' },
        },
    navItem: isSP
      ? {
          open: {
            y: 0,
            opacity: 1,
            transition: {
              y: { stiffness: 1000, velocity: -100 },
            },
          },
          closed: {
            y: 0,
            opacity: 0,
            transition: {
              y: { stiffness: 1000 },
            },
          },
        }
      : {
          open: { opacity: 1 },
          closed: { opacity: 1 },
        },
    share: isSP
      ? {
          open: {
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
              staggerChildren: 0.07,
              delayChildren: 0.2,
            },
          },
          closed: {
            opacity: 0,
            y: 10,
            transition: { duration: 0.25, staggerChildren: 0.05, staggerDirection: -1 },
          },
        }
      : {
          open: {},
          closed: {},
        },
    shareItem: isSP
      ? {
          open: {
            y: 0,
            opacity: 1,
            transition: {
              y: { duration: 0.5 },
            },
          },
          closed: {
            y: 0,
            opacity: 0,
            transition: {
              y: { duration: 0.25 },
            },
          },
        }
      : {
          open: { opacity: 1 },
          closed: { opacity: 1 },
        },
  };

  const handleShare = useCallback((e: MouseEvent, type: string) => {
    e.preventDefault();
    if (!ENV.IS_TOUCH_DEVICE && type === 'messenger') {
      if (window) {
        const pageUrl = BASE_URL;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).FB.ui({
          method: 'send',
          link: pageUrl,
        });
      }
    } else {
      let option = '';
      if (ENV.IS_TOUCH_DEVICE && type === 'messenger') {
        option = FB_APP_ID;
      }
      const link = generateShareLink(BASE_URL as string, type, SEO_INFO.HOME.title, option);
      if (link !== '#') {
        window.open(link, '_blank');
      }
    }
  }, []);

  const handleNavClick = (e: MouseEvent, path: string) => {
    e.preventDefault();
    setIsOpen(false);
    setTimeout(() => {
      router.push(path, path, {
        scroll: true,
      });
    }, 400);
  };

  return (
    <motion.header
      className={mapModifiers('o-global-header', isOpen && 'open')}
      initial="hidden"
      animate="enter"
      variants={animationVariants.root}
    >
      <motion.div
        className="o-global-header__wrapper"
        initial={false}
        animate={isSP ? (isOpen ? 'open' : 'closed') : 'open'}
      >
        <motion.div
          ref={backgroundRef}
          layout
          className="o-global-header__background"
          variants={animationVariants.background}
        ></motion.div>
        <div className="o-global-header__content">
          <div className="o-global-header__brand">
            <a href="/" onClick={e => handleNavClick(e, '/')}>
              <img src={logoImage} width="101" height="32" alt="Branding" />
            </a>
          </div>
          <button
            ref={toggleRef}
            className="o-global-header__nav-toggle"
            aria-label="nav toggle button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon name={isOpen ? 'close' : 'ellipsis'} color="white" />
          </button>
          <motion.nav className="o-global-header__nav" variants={animationVariants.nav}>
            <ul className="o-global-header__menu">
              {navList.map((item, index) => (
                <motion.li key={index} className="o-global-header__menu-item" variants={animationVariants.navItem}>
                  <a href={item.path} onClick={e => handleNavClick(e, item.path)}>
                    {item.text}
                  </a>
                </motion.li>
              ))}
            </ul>
            <motion.div className="o-global-header__bottom" variants={animationVariants.share}>
              <div className="o-global-header__share-label">Share us on</div>
              <div className="o-global-header__share-links">
                <motion.a
                  className="o-global-header__share-link"
                  href="#"
                  onClick={e => handleShare(e, 'whatsapp')}
                  variants={animationVariants.shareItem}
                  aria-label="Share on Whatsapp"
                >
                  <span>Whatsapp</span>
                  <Icon name="whatsapp" color="white" />
                </motion.a>
                <motion.a
                  className="o-global-header__share-link"
                  href="#"
                  onClick={e => handleShare(e, 'messenger')}
                  variants={animationVariants.shareItem}
                  aria-label="Share on Messenger"
                >
                  <span>Messenger</span>
                  <Icon name="messenger" color="white" />
                </motion.a>
                <motion.a
                  className="o-global-header__share-link"
                  href="#"
                  onClick={e => handleShare(e, 'telegram')}
                  variants={animationVariants.shareItem}
                  aria-label="Share on Telegram"
                >
                  <span>Telegram</span>
                  <Icon name="telegram" color="white" />
                </motion.a>
                <motion.a
                  className="o-global-header__share-link"
                  href="#"
                  onClick={e => handleShare(e, 'facebook')}
                  variants={animationVariants.shareItem}
                  aria-label="Share on Facebook"
                >
                  <span>Facebook</span>
                  <Icon name="facebook" color="white" />
                </motion.a>
                <motion.a
                  className="o-global-header__share-link"
                  href="#"
                  onClick={e => handleShare(e, 'twitter')}
                  variants={animationVariants.shareItem}
                  aria-label="Share on Twitter"
                >
                  <span>Twitter</span>
                  <Icon name="twitter" color="white" />
                </motion.a>
              </div>
              <div className="o-global-header__legal">
                <motion.a
                  href="/terms-and-conditions"
                  className="o-global-header__legal-link"
                  onClick={e => handleNavClick(e, '/terms-and-conditions')}
                  variants={animationVariants.shareItem}
                >
                  Terms &amp; Conditions
                </motion.a>
                <motion.a
                  href="/privacy-policy"
                  className="o-global-header__legal-link"
                  onClick={e => handleNavClick(e, '/privacy-policy')}
                  variants={animationVariants.shareItem}
                >
                  Privacy Policy
                </motion.a>
              </div>
            </motion.div>
          </motion.nav>
        </div>
      </motion.div>
    </motion.header>
  );
};
